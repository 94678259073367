import * as React from "react"
import PropTypes from "prop-types"

import Navi from "./header-navi"

const Header = ({ siteTitle, division }) => (
  <header>
    {division !== '' && <Navi division={division} />}
  </header>
)

Header.propTypes = {
  division: PropTypes.string,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  division: ``,
  siteTitle: ``,
}

export default Header
