
import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import * as Styles from "./header-navi.module.scss"

import imgLogoGyosei from "../images/common/rectangle_gyosei.png"
import imgLogoSupport from "../images/common/rectangle_support.png"
import iconContactGyosei from "../images/gyosei/contact.svg"
import iconChargeGyosei from "../images/gyosei/charge.svg"
import iconAccessGyosei from "../images/gyosei/access.svg"
import iconContactSupport from "../images/support/contact.svg"
import iconChargeSupport from "../images/support/charge.svg"
import iconAccessSupport from "../images/support/access.svg"


const Navi = ({ division }) => {

  const DATA = useStaticQuery( graphql`
      query {
        site {
          siteMetadata {
            divisionJa { gyosei, support },
            menuJa {contact, charge, access}
          }
        }
      }
    `
  )

  const GYOSEI_JA = DATA.site.siteMetadata.divisionJa.gyosei
  const SUPPORT_JA = DATA.site.siteMetadata.divisionJa.support
  const CONTACT_JA = DATA.site.siteMetadata.menuJa.contact
  const CHARGE_JA = DATA.site.siteMetadata.menuJa.charge
  const ACCSESS_JA = DATA.site.siteMetadata.menuJa.access

  // 基本は行政書士事務所
  let divisionJa = GYOSEI_JA
  let divisionStyles = Styles.gyosei
  let imgTop = imgLogoGyosei
  let otherDivision = 'support'
  let otherDivisionJa = SUPPORT_JA
  let imgOtherDivision = imgLogoSupport
  let iconContact = iconContactGyosei
  let iconCharge = iconChargeGyosei
  let iconAccess = iconAccessGyosei
  let styleClass = `text-gyosei_color border-b-2 border-gyosei_color`

  // サポートURLの場合はオフィス
  if(division === 'support') {
    divisionJa = SUPPORT_JA
    divisionStyles = Styles.support
    imgTop = imgLogoSupport
    otherDivision = 'gyosei'
    otherDivisionJa = GYOSEI_JA
    imgOtherDivision = imgLogoGyosei
    iconContact = iconContactSupport
    iconCharge = iconChargeSupport
    iconAccess = iconAccessSupport
    styleClass = `text-support_color border-b-2 border-support_color`
  }

  let linkTop = <Link to={`/${division}`} className={Styles.list}><img src={imgTop} className={Styles.logo} alt={divisionJa} /></Link>
  let linkContact = <Link to={`/${division}/contact`} className={Styles.list}><img src={iconContact} alt={CONTACT_JA} />{CONTACT_JA}</Link>
  let linkCharge = <Link to={`/${division}/charge`} className={Styles.list}><img src={iconCharge} alt={CHARGE_JA} />{CHARGE_JA}</Link>
  let linkAccess = <Link to={`/${division}/access`} className={Styles.list}><img src={iconAccess} alt={ACCSESS_JA} />{ACCSESS_JA}</Link>
  let linkOther = <Link to={`/${otherDivision}`} className={Styles.list}><img src={imgOtherDivision} className={Styles.logo} alt={otherDivisionJa} /></Link>

  return (
    <nav className={`${Styles.nav} ${styleClass}`}>
      <ul className={Styles.pc_menu}>
        <li className="w-full">{linkTop}</li>
        <li className="w-full">{linkContact}</li>
        <li className="w-full">{linkCharge}</li>
        <li className="w-full">{linkAccess}</li>
        <li className="w-full">{linkOther}</li>
      </ul>
      <div className={Styles.sp_menu}>
        {linkTop}
      </div>
    </nav>
  )
}

export default Navi
